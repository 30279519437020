import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Section } from "../App";
import { useEffect, useState } from "react";
import { nextSectionLink } from "../logic";

interface TopNavigationArrowProps {
  currentSection: Section;
}

export default function BottomNavigationArrow(props: TopNavigationArrowProps) {
  const { currentSection } = props;

  const [showNavigationText, setShowNavigationText] = useState(false);

  useEffect(() => {
    const fadeInTimeout = setTimeout(() => {
      setShowNavigationText(true);
    }, 2000);

    // Set a timeout to trigger the fade out after 15 seconds
    const fadeOutTimeout = setTimeout(() => {
      setShowNavigationText(false);
    }, 25000);

    // Clear the timeouts when the component unmounts
    return () => {
      clearTimeout(fadeInTimeout);
      clearTimeout(fadeOutTimeout);
    };
  });

  return (
    <div className="fixed bottom-0 w-full flex justify-center">
      <a
        id="nextArrowAnchor"
        className="w-1/2 sm:w-3/4 py-6 flex flex-col items-center"
        href={nextSectionLink(currentSection)}
      >
        <p
          className={`text-xs text-center mb-2 text-secondaryLight dark:text-secondaryDark duration-500 opacity-0 ${
            showNavigationText && "opacity-100"
          }`}
        >
          this site is interactive - navigate using your keyboard, on-screen arrows, or sidebar!
        </p>
        <ChevronDownIcon className="w-6 animate-pulse" />
      </a>
    </div>
  );
}
