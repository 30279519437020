import { Section } from "./App";
import { SECTIONS } from "./sections/Sidebar";

export function nextSectionLink(currentSection: Section) {
  const currentSectionIdx = SECTIONS.indexOf(currentSection);

  if (currentSectionIdx === SECTIONS.length - 1) return "";

  return SECTIONS[currentSectionIdx + 1]?.sectionId || "";
}

export function previousSectionLink(currentSection: Section) {
  const currentSectionIdx = SECTIONS.indexOf(currentSection);

  if (!currentSectionIdx) return "";

  return SECTIONS[currentSectionIdx - 1]?.sectionId || "";
}
