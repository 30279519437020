import SectionContainer from "../components/SectionContainer";

export default function About() {
  return (
    <SectionContainer id="about">
      <div>
        <h2 className="text-2xl mb-4">about me</h2>
        <p className="text-sm sm:text-md">- seattle-based software engineer</p>
        <p className="text-sm sm:text-md">
          - currently coding at{" "}
          <a
            className="text-secondaryLight dark:text-secondaryDark hover:brightness-75 duration-200"
            href="https://www.moderntreasury.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            modern treasury
          </a>
        </p>
        <p className="text-sm sm:text-md">
          - formerly @ amazon and{" "}
          <a
            className="text-secondaryLight dark:text-secondaryDark hover:brightness-75 duration-200"
            href="https://www.docugami.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            docugami
          </a>
        </p>
        <p className="text-sm sm:text-md">- university of washington computer science graduate</p>
        <p className="text-sm sm:text-md">- eaten my way through 20 countries (and counting!)</p>
      </div>
    </SectionContainer>
  );
}
